const tabButtons = document.querySelectorAll('button.st-button')
const tabSegments = document.querySelectorAll('.st-content')

/* --------------------------------------
---- Update the tab on button click -----
-------------------------------------- */
// button function
const updateTabDisplay = (event) => {

  tabButtons.forEach(button => {
    // find the clicked tab, get it's attribute
    const currentTab = event.currentTarget
    const tabName = currentTab.getAttribute('data-id')

    // set the url to show the tab name with a #
    const url = `#${tabName}`
    window.history.pushState(null, "", url)
    
    // now find the correct tab
    const tabSegment = document.querySelector(`.st-content[data-segment="${tabName}"]`)
    // remove all of the active classes
    tabSegments.forEach(tag => tag.classList.remove('active'))
    tabButtons.forEach(tag => tag.classList.remove('active'))

    // now add the active classes to match the click
    currentTab.classList.add('active')
    tabSegment.classList.add('active')

  })

}

const onTabClick = () => {
  updateTabDisplay(event)
}

if (tabButtons) {
  tabButtons.forEach(button => {
    button.addEventListener('click', onTabClick)
  })
}

/* ------------------------------------------------------------
---- Link the service boxes to the corresponding page tab -----
------------------------------------------------------------ */
const pageButtons = document.querySelectorAll('.js-tab-link')

const pageLinkToTab = () => {
  // get the url, of the loaded page, with js and remove the hash sign
  const url = window.location.hash
  const tabName = url.replace('#', '')
  // find the current tab button and the tab segment by matching the url's data attribute/tabName
  const currentTab = document.querySelector(`button.st-button[data-id="${tabName}"]`)
  const tabSegment = document.querySelector(`.st-content[data-segment="${tabName}"]`)

  if (window.location.hash) {
    // get the tabs container and the position on the page - set the scroll position
    const tabbedSection = document.querySelector('section.service-tab-buttons')
    let scrollPosition = (tabbedSection.getBoundingClientRect().top - 40)
    // remove previously set classes
    tabSegments.forEach(tag => tag.classList.remove('active'))
    tabButtons.forEach(tag => tag.classList.remove('active'))
    // add the active classes to the current tab button and tab segment
    currentTab.classList.add('active')
    tabSegment.classList.add('active')

    // set the scroll position to the top of the tabbed section
    window.scrollTo(0,scrollPosition)
  }
}

if (pageButtons) {
  pageLinkToTab()
}

/* --------------------------------------------------
---- Scroll page to the download form on mobile -----
-------------------------------------------------- */
const downloadSidebar = document.querySelector('aside.download-aside-section')
const downloadButton = document.querySelector('.download-side-btn')

const onClickScrollPage = (event) => {

  const checkURL = window.location.search

  if (window.innerWidth < 900 && checkURL.length > 1) {
    // if the window is less than breakpoint and the form contains the submitted query
    // get the scroll position of the download side bar
    let scrollPosition = (downloadSidebar.getBoundingClientRect().top - 40)
    // now set the page to load at the scrollPosition height
    window.scrollTo(0,scrollPosition)
  }

}

if (downloadSidebar) {
  onClickScrollPage()
}